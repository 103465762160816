<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('damReport.dailyPriceListReport') }}</h4>
                    </template>
                    <template v-slot:body>
                        <b-row>
                            <b-col lg="12" sm="12">
                                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                    <b-form @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset" >
                                        <b-row>
                                          <b-col lg="6" sm="12">
                                            <ValidationProvider name="Division" vid="division_id" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="division_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.division') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <vue-multi-select
                                                      id="division_id"
                                                      v-model="values_division"
                                                      search
                                                      historyButton
                                                      :filters="filters_division"
                                                      :options="options"
                                                      :selectOptions="divisionList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      class="w-100"
                                                      :btnLabel="btnLabel"
                                                      btnClass="w-100 d-block text-left"
                                                      />
                                                    <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                                      {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" md="6" sm="12">
                                            <ValidationProvider name="District" vid="district_id" rules="required">
                                                <b-form-group
                                                    class="row"
                                                    label-cols-sm="4"
                                                    label-for="district_id"
                                                    slot-scope="{ valid, errors }"
                                                    >
                                                    <template v-slot:label>
                                                    {{ $t('globalTrans.district') }} <span class="text-danger">*</span>
                                                    </template>
                                                    <vue-multi-select
                                                      id="district_id"
                                                      v-model="values_district"
                                                      search
                                                      historyButton
                                                      :filters="filters_district"
                                                      :options="options"
                                                      :selectOptions="districtList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      class="w-100"
                                                      :btnLabel="btnLabel"
                                                      btnClass="w-100 d-block text-left"
                                                      />
                                                    <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                                      {{ errors[0] }}
                                                    </div>
                                                </b-form-group>
                                            </ValidationProvider>
                                        </b-col>
                                          <b-col lg="6" md="6" sm="12">
                                              <ValidationProvider name="Upazila" vid="upazila_id" rules="required">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="4"
                                                      label-for="upazila_id"
                                                      slot-scope="{ valid, errors }"
                                                      >
                                                      <template v-slot:label>
                                                      {{ $t('globalTrans.upazila') }} <span class="text-danger">*</span>
                                                      </template>
                                                      <vue-multi-select
                                                      id="upazila_id"
                                                      v-model="values_upazila"
                                                      search
                                                      historyButton
                                                      :filters="filters_upazila"
                                                      :options="options"
                                                      :selectOptions="upazilaList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      class="w-100"
                                                      :btnLabel="btnLabel"
                                                      btnClass="w-100 d-block text-left"
                                                      />
                                                      <div class="invalid-feedback d-block"   v-if="errors.length && clickSubmit">
                                                        {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" md="6" sm="12">
                                              <ValidationProvider name="Market" vid="market_id" rules="required">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="4"
                                                      label-for="market_id"
                                                      slot-scope="{ valid, errors }"
                                                      >
                                                      <template v-slot:label>
                                                      {{ $t('portal.market') }} <span class="text-danger">*</span>
                                                      </template>
                                                      <vue-multi-select
                                                      id="market_id"
                                                      v-model="values_market"
                                                      search
                                                      historyButton
                                                      :filters="filters_market"
                                                      :options="options"
                                                      :selectOptions="marketList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      class="w-100"
                                                      :btnLabel="btnLabel"
                                                      btnClass="w-100 d-block text-left"
                                                      />
                                                      <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                                        {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                          <b-col lg="6" md="6" sm="12">
                                              <ValidationProvider name="Price Type" vid="price_type" rules="required">
                                                  <b-form-group
                                                      class="row"
                                                      label-cols-sm="4"
                                                      label-for="price_type"
                                                      slot-scope="{ valid, errors }"
                                                      >
                                                      <template v-slot:label>
                                                      {{ $t('crop_price_config.price_type') }} <span class="text-danger">*</span>
                                                      </template>
                                                      <vue-multi-select
                                                      id="price_type"
                                                      v-model="values_price_type"
                                                      search
                                                      historyButton
                                                      :filters="filters_price_type"
                                                      :options="options"
                                                      :selectOptions="priceTypeList"
                                                      :state="errors[0] ? false : (valid ? true : null)"
                                                      class="w-100"
                                                      :btnLabel="btnLabel"
                                                      btnClass="w-100 d-block text-left"
                                                      />
                                                      <div class="invalid-feedback d-block" v-if="errors.length && clickSubmit">
                                                        {{ errors[0] }}
                                                      </div>
                                                  </b-form-group>
                                              </ValidationProvider>
                                          </b-col>
                                          <b-col lg="12" md="12" sm="12">
                                              <div class="pt-4 pb-4">
                                              <ValidationProvider name="Select Time" vid="select_type" rules="">
                                                  <b-form-radio-group
                                                      v-model="search.select_type"
                                                      :options="timePreiodList"
                                                  >
                                                  </b-form-radio-group>
                                              </ValidationProvider>
                                              </div>
                                          </b-col>
                                          <b-col v-if="search.select_type ==='Weekly'" lg="12" md="12" sm="12">
                                              <b-row>
                                                  <b-col lg="4" md="4" sm="12">
                                                  <ValidationProvider name="Year" vid="year" rules="required|min_value:1">
                                                    <b-form-group
                                                        class="row"
                                                        label-cols-sm="3"
                                                        label-for="year"
                                                        slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                        {{ $t('crop_price_config.year') }} <span class="text-danger">*</span>
                                                        </template>
                                                        <b-form-select
                                                        plain
                                                        v-model="search.year"
                                                        :options="yearList"
                                                        id="year"
                                                        :state="errors[0] ? false : (valid ? true : null)"
                                                        >
                                                        <template v-slot:first>
                                                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                        </template>
                                                        </b-form-select>
                                                        <div class="invalid-feedback">
                                                        {{ errors[0] }}
                                                        </div>
                                                    </b-form-group>
                                                  </ValidationProvider>
                                                  </b-col>
                                                  <b-col lg="4" md="4" sm="12">
                                                      <ValidationProvider name="Month" vid="month_id" rules="required|min_value:1">
                                                          <b-form-group
                                                              class="row"
                                                              label-cols-sm="3"
                                                              label-for="month_id"
                                                              slot-scope="{ valid, errors }"
                                                              >
                                                              <template v-slot:label>
                                                              {{ $t('crop_price_config.month') }} <span class="text-danger">*</span>
                                                              </template>
                                                              <b-form-select
                                                              plain
                                                              v-model="search.month_id"
                                                              :options="monthList"
                                                              id="month_id"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                              >
                                                              <template v-slot:first>
                                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                              </template>
                                                              </b-form-select>
                                                              <div class="invalid-feedback">
                                                              {{ errors[0] }}
                                                              </div>
                                                          </b-form-group>
                                                      </ValidationProvider>
                                                  </b-col>
                                                  <b-col lg="4" md="4" sm="12">
                                                      <ValidationProvider name="Week" vid="week_id" rules="required|min_value:1">
                                                          <b-form-group
                                                              class="row"
                                                              label-cols-sm="3"
                                                              label-for="week_id"
                                                              slot-scope="{ valid, errors }"
                                                              >
                                                              <template v-slot:label>
                                                              {{ $t('crop_price_config.week') }} <span class="text-danger">*</span>
                                                              </template>
                                                              <b-form-select
                                                              plain
                                                              v-model="search.week_id"
                                                              :options="weekList"
                                                              id="week_id"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                              >
                                                              <template v-slot:first>
                                                                  <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                                                              </template>
                                                              </b-form-select>
                                                              <div class="invalid-feedback">
                                                              {{ errors[0] }}
                                                              </div>
                                                          </b-form-group>
                                                      </ValidationProvider>
                                                  </b-col>
                                              </b-row>
                                          </b-col>
                                          <b-col :class="{ hide: search.select_type ==='Weekly' }" lg="12" md="12" sm="12">
                                              <b-row>
                                                  <b-col lg="6" sm="12">
                                                      <ValidationProvider name="Price Date" vid="price_date" rules="required">
                                                          <b-form-group
                                                              class="row"
                                                              label-cols-sm="4"
                                                              label-for="price_date"
                                                              slot-scope="{ valid, errors }"
                                                          >
                                                          <template v-slot:label>
                                                              {{ $t('crop_price_config.price_date')}} <span class="text-danger">*</span>
                                                          </template>
                                                              <b-form-input
                                                              class="datepicker"
                                                              v-model="search.price_date"
                                                              :state="errors[0] ? false : (valid ? true : null)"
                                                              placeholder="yyyy-mm-dd"
                                                              ></b-form-input>
                                                              <div class="invalid-feedback">
                                                              {{ errors[0] }}
                                                              </div>
                                                          </b-form-group>
                                                      </ValidationProvider>
                                                  </b-col>
                                              </b-row>
                                          </b-col>
                                        </b-row>
                                        <div class="row">
                                            <div class="col-sm-3"></div>
                                            <div class="col text-right">
                                                <b-button type="submit" variant="primary" class="mr-2" @click="clickSubmit=true">{{ $t('globalTrans.search') }}</b-button>
                                            </div>
                                        </div>
                                    </b-form>
                                </ValidationObserver>
                            </b-col>
                        </b-row>
                    </template>
                </iq-card>
                <b-row >
                  <b-col md="12" v-if="showData">
                    <b-overlay :show="loading">
                      <iq-card v-if='datas.length'>
                        <template v-slot:body>
                          <b-row>
                            <b-col md="12">
                              <iq-card>
                                <template v-slot:headerTitle>
                                  <h4 class="card-title">{{ $t('damReport.dailyPriceListReport') }}</h4>
                                </template>
                                <template v-slot:headerAction>
                                  <b-button class="btn_add_new mr-2" @click="pdfExport">
                                    <i class="far fa-file-pdf"></i>{{  $t('globalTrans.export_pdf') }}
                                  </b-button>
                                  <export-excel
                                    class="btn btn_add_new"
                                    :data="excelData"
                                    :title="headerValue"
                                    worksheet="Report Sheet"
                                    name="market-daily-price-report.xls">
                                    <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
                                  </export-excel>
                                </template>
                                <template v-slot:body>
                                  <b-overlay>
                                    <b-row mt-5>
                                      <b-col md="12" class="table-responsive">
                                        <div style="border: 2px solid;margin:10px;padding:10px">
                                          <b-row>
                                            <b-col>
                                              <list-report-head :base-url="warehouseServiceBaseUrl" uri="master-warehouse-report-heading/detail" :org-id="13">
                                                <template v-slot:projectNameSlot>
                                                  {{ }}
                                                </template>
                                                <slot v-if="search.select_type === 'Daily'">
                                                  {{ $t('damReport.dailyPriceListReport') }}
                                                </slot>
                                                <slot v-else>
                                                  {{ $t('damReport.weeklyPriceListReport') }}
                                                </slot>
                                              </list-report-head>
                                            </b-col>
                                          </b-row>
                                          <b-row>
                                            <b-col>
                                              <div class="text-center">
                                                <table style="width:100%;color:black;">
                                                  <tr>
                                                    <td class="align-top" align="right" width="15%">{{ $t('globalTrans.division') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ getDivisionName(search.division_id) }}</td>
                                                  </tr>
                                                  <tr>
                                                    <td class="align-top" align="right">{{ $t('globalTrans.district') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ getDistrictName(search.district_id) }}</td>
                                                  </tr>
                                                  <tr>
                                                    <td class="align-top" align="right">{{ $t('globalTrans.upazila') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ getUpazilaNameCustom(search.upazila_id) }}</td>
                                                  </tr>
                                                  <tr>
                                                    <td class="align-top" align="right">{{ $t('portal.market') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ getMarketName(search.market_id) }}</td>
                                                  </tr>
                                                  <tr>
                                                    <td class="align-top" align="right">{{ $t('crop_price_config.price_type') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ getPriceTypeName(search.price_type_id) }}</td>
                                                  </tr>
                                                  <tr v-if="search.select_type === 'Daily'">
                                                    <td class="align-top" align="right">{{ $t('crop_price_config.price_date') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left">{{ search.price_date | dateFormat }}</td>
                                                  </tr>
                                                  <tr v-if="search.select_type ==='Weekly'">
                                                    <td class="align-top" align="right" >{{ $t('crop_price_config.year') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ ($i18n.locale==='bn') ? search.year_bn : search.year_en  }}</td>
                                                  </tr>
                                                  <tr v-if="search.select_type ==='Weekly'">
                                                    <td class="align-top" align="right" >{{ $t('crop_price_config.month') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >
                                                      {{ ($i18n.locale==='bn') ? search.month_bn : search.month_en }}
                                                    </td>
                                                  </tr>
                                                  <tr v-if="search.select_type ==='Weekly'">
                                                    <td class="align-top" align="right" >{{ $t('crop_price_config.week') }}</td>
                                                    <td class="align-top" align="center" width="5%">:</td>
                                                    <td class="align-top" align="left" >{{ ($i18n.locale==='bn') ? search.week_bn : search.week_en }}</td>
                                                  </tr>
                                                </table>
                                              </div>
                                            </b-col>
                                          </b-row>
                                          <!-- <b-row>
                                            <b-col>
                                              <b-table-simple class="tg mt-3" bordered hover small caption-top responsive>
                                                <b-thead>
                                                  <b-tr>
                                                    <b-th  class="text-center">{{ $t('damReport.commodity_group') }}</b-th>
                                                    <b-th class="text-center">{{ $t('damReport.product_name_and_description') }}</b-th>
                                                    <slot v-for="val in search.price_type_id">
                                                      <b-th class="text-center">{{ $t('damReport.measurement') }}</b-th>
                                                      <b-th class="text-center"> {{ ($i18n.locale==='bn') ? val.text_bn : val.text_en }} {{ $t('damReport.rate') }}</b-th>
                                                    </slot>
                                                  </b-tr>
                                                </b-thead>
                                                <b-tbody  v-for="(info, index) in datas" :key="index">
                                                  <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.group.group_name_bn : info.group.group_name }}</b-td>
                                                  <b-td class="text-center">{{ ($i18n.locale==='bn') ? info.commodity.commodity_name_bn : info.commodity.commodity_name }}</b-td>
                                                  <slot  v-for="(val, index) in search.price_type_id">
                                                    <b-td :key="index" v-if="val.text_en == 'Retail'" class="text-center">{{ ($i18n.locale==='bn') ? info.commodity.unit_retail.unit_name_bn : info.commodity.unit_retail.unit_name }} </b-td>
                                                    <b-td class="text-center" v-if="val.text_en == 'Retail'">{{$n(info.r_lowestPrice)}} - {{$n(info.r_highestPrice)}}</b-td>
                                                    <b-td class="text-center" v-if="val.text_en == 'Wholesale'">{{ ($i18n.locale==='bn') ? info.commodity.unit_whole_sale.unit_name_bn : info.commodity.unit_whole_sale.unit_name }}</b-td>
                                                    <b-td class="text-center" v-if="val.text_en == 'Wholesale'">{{$n(info.w_lowestPrice)}} - {{$n(info.w_highestPrice)}}</b-td>
                                                  </slot>
                                                </b-tbody>
                                              </b-table-simple>
                                            </b-col>
                                          </b-row> -->
                                          <b-row>
                                                <b-col sm="12" class="steaky-header">
                                                    <slot>
                                                        <b-table head-variant="primary" :sticky-header="stickyHeader" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="datas" :fields="columns">
                                                        <template v-slot:cell(r_lowestPrice)="data">
                                                            {{ parseFloat(data.item.r_lowestPrice) || parseFloat(data.item.r_highestPrice) ? $n(parseFloat(data.item.r_lowestPrice)) + '-' + $n(parseFloat(data.item.r_highestPrice)) : '' }}
                                                        </template>
                                                        <template v-slot:cell(w_lowestPrice)="data">
                                                            {{ parseFloat(data.item.w_lowestPrice) || parseFloat(data.item.w_highestPrice) ? $n(parseFloat(data.item.w_lowestPrice).toFixed(2)) + '-' + $n(parseFloat(data.item.w_highestPrice)) : '' }}
                                                        </template>
                                                        </b-table>
                                                    </slot>
                                                </b-col>
                                          </b-row>
                                        </div>
                                      </b-col>
                                    </b-row>
                                  </b-overlay>
                                </template>
                              </iq-card>
                            </b-col>
                          </b-row>
                        </template>
                      </iq-card>
                      <div class="panel-body text-center mt-3" v-else>
                        <h6 class="text-danger" v-if="!firstTime">{{ $t('globalTrans.noDataFound') }}</h6>
                      </div>
                    </b-overlay>
                  </b-col>
                </b-row>
            </b-col>
        </b-row>
    </b-container>
</template>
<style scoped>
.hide {
  visibility: hidden !important;
}
</style>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { agriMarketingServiceBaseUrl, warehouseServiceBaseUrl } from '@/config/api_config'
import flatpickr from 'flatpickr'
import ListReportHead from '@/components/custom/ListReportHead.vue'
import ExportPdf from './export-pdf_details'
import excel from 'vue-excel-export'
import Vue from 'vue'
import { dateFormat } from '@/Utils/fliter'
import Store from '@/store'
import vueMultiSelect from 'vue-multi-select'
import 'vue-multi-select/dist/lib/vue-multi-select.css'
Vue.use(excel)
// import moment from 'moment'
const today = new Date().toISOString().substr(0, 10)

export default {
    props: ['id'],
    components: {
        ValidationObserver,
        ValidationProvider,
        ListReportHead,
        vueMultiSelect
    },
    created () {
      if (this.id) {
        const tmp = this.getEditingData()
        this.search = tmp
      }
    },
    data () {
        return {
            btnLabelDivision: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            btnLabelDistrict: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            btnLabelUpazila: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            btnLabelMarket: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            btnLabelPriceType: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            btnLabel: (values) => values.length ? this.getNames(values) : this.$t('globalTrans.select'),
            AveragePriceText: '',
            maxPriceMsg: '',
            minPriceMsg: '',
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            search: {
              division_id: [],
              district_id: [],
              upazila_id: [],
              market_id: [],
              price_type_id: [],
              select_type: 'Daily',
              // price_type_id: '',
              year: 0,
              month_id: 0,
              week_id: 0,
              price_date: today
            },
            stickyHeader: true,
            currentDate: '',
            listItemofyerterday: '',
            districtList: [],
            upazilaList: [],
            marketList: [],
            commoditySubGroupList: [],
            headerExcelDefault: {
                orgName: null,
                orgNameBn: null,
                address: null,
                address_bn: null
            },
            warehouseServiceBaseUrl: warehouseServiceBaseUrl,
            datas: [],
            // loading: false,
            showData: false,
            firstTime: true,
            values_division: [],
            values_district: [],
            values_upazila: [],
            values_market: [],
            values_price_type: [],
            filters_division: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
            filters_district: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
            filters_upazila: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
            filters_market: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
            filters_price_type: [{
              nameAll: this.$t('globalTrans.select_all'),
              nameNotAll: this.$t('globalTrans.deselect_all'),
              func () {
                return true
              }
            }],
            options: {
              multi: true,
              groups: false,
              labelName: 'text',
              labelValue: 'value',
              cssSelected: option => (option.selected ? { 'background-color': '#5764c6' } : '')
            },
            clickSubmit: false
        }
    },
    mounted () {
        core.index()
        flatpickr('.datepicker', {})
        this.headerDataExcel()
        this.AveragePriceText = (parseInt(this.search.HighestPrice) + parseInt(this.search.LowestPrice)) / 2
    },
    computed: {
      // btnLabelMarket () {
      //   return (values1) => values1.length ? this.getMarketName(this.search.market_id) : this.$t('globalTrans.select')
      // },
      currentLocale () {
        return this.$i18n.locale
      },
      headerValue: function () {
        const headerVal = []
        if (this.$i18n.locale === 'en') {
          headerVal[0] = this.headerExcelDefault.orgName
          headerVal[1] = this.headerExcelDefault.address
          headerVal[2] = this.$t('damReport.dailyPriceListReport')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
          headerVal[6] = this.$t('portal.market') + ': ' + this.getMarketName(this.search.market_id)
          headerVal[7] = this.$t('crop_price_config.price_type') + ': ' + this.getPriceTypeName(this.search.price_type_id)
          if (this.search.select_type === 'Weekly') {
            headerVal[8] = this.$t('crop_price_config.year') + ':  ' + this.search.year_en
            headerVal[9] = this.$t('crop_price_config.month') + ': ' + this.search.month_en + '; '
            headerVal[10] = this.$t('crop_price_config.week') + ' : ' + this.search.week_en
          } else {
            headerVal[8] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.search.price_date)
          }
        } else {
          headerVal[0] = this.headerExcelDefault.orgNameBn
          headerVal[1] = this.headerExcelDefault.address_bn
          headerVal[2] = this.$t('damReport.dailyPriceListReport')
          headerVal[3] = this.$t('globalTrans.division') + ':  ' + this.getDivisionName(this.search.division_id)
          headerVal[4] = this.$t('globalTrans.district') + ': ' + this.getDistrictName(this.search.district_id)
          headerVal[5] = this.$t('globalTrans.upazila') + ':  ' + this.getUpazilaNameCustom(this.search.upazila_id)
          headerVal[6] = this.$t('portal.market') + ': ' + this.getMarketName(this.search.market_id)
          headerVal[7] = this.$t('crop_price_config.price_type') + ': ' + this.getPriceTypeName(this.search.price_type_id)
          if (this.search.select_type === 'Weekly') {
            headerVal[8] = this.$t('crop_price_config.year') + ':  ' + this.search.year_bn
            headerVal[9] = this.$t('crop_price_config.month') + ': ' + this.search.month_bn + '; '
            headerVal[10] = this.$t('crop_price_config.week') + ' : ' + this.search.week_bn
          } else {
            headerVal[8] = this.$t('crop_price_config.price_date') + ':  ' + dateFormat(this.search.price_date)
          }
        }
        return headerVal
      },
      excelData: function () {
        const listData = this.datas
        var serial = 0
        const listContractor = listData.map(info => {
          serial++
          if (this.$i18n.locale === 'en') {
            let boxTable = {
              'Sl ': serial,
              'Commodity Group ': info.group_name,
              'Product name and description ': info.commodity_name
            }
            if (this.search.price_type_id.length > 0) {
              if (this.search.price_type_id.includes('Retail')) {
                boxTable = {
                  ...boxTable,
                  'Measurement ': info.unit_name,
                  'Retail Price (in Taka) Lowest Price - Highest Price': parseFloat(info.r_lowestPrice) || parseFloat(info.r_highestPrice) ? this.$n(parseFloat(info.r_lowestPrice).toFixed(2), { useGrouping: false }) + '--' + this.$n(parseFloat(info.r_highestPrice).toFixed(2), { useGrouping: false }) : ' '
                }
              }
              if (this.search.price_type_id.includes('Wholesale')) {
                boxTable = {
                  ...boxTable,
                  Measurement: info.w_unit_name,
                  'Wholesale Price (in Taka) Lowest Price - Highest Price': parseFloat(info.w_highestPrice) || parseFloat(info.w_highestPrice) ? this.$n(parseFloat(info.w_highestPrice).toFixed(2), { useGrouping: false }) + '--' + this.$n(parseFloat(info.w_highestPrice).toFixed(2), { useGrouping: false }) : ' '
                }
              }
            }
            return boxTable
          } else {
            let boxTable = {
              'ক্রমিক সংখ্যা': this.$n(serial),
              'পণ্যের গ্রুপ': info.group_name_bn,
              'পণ্যের নাম ও বিবরণ': info.commodity_name_bn
            }
            if (this.search.price_type_id.length > 0) {
              if (this.search.price_type_id.includes('Retail')) {
                boxTable = {
                  ...boxTable,
                  'একক ': info.unit_name_bn,
                  'খুচরা দর(টাকায়) সর্বনিম্ন দর - সর্বোচ্চ দর': parseFloat(info.r_lowestPrice) || parseFloat(info.r_highestPrice) ? this.$n(parseFloat(info.r_lowestPrice).toFixed(2), { useGrouping: false }) + '--' + this.$n(parseFloat(info.r_highestPrice).toFixed(2), { useGrouping: false }) : ' '
                }
              }
              if (this.search.price_type_id.includes('Wholesale')) {
                boxTable = {
                  ...boxTable,
                  'একক  ': info.w_unit_name_bn,
                  'পাইকারি দর(টাকায়) সর্বনিম্ন দর - সর্বোচ্চ দর': parseFloat(info.w_highestPrice) || parseFloat(info.w_highestPrice) ? this.$n(parseFloat(info.w_highestPrice).toFixed(2), { useGrouping: false }) + '--' + this.$n(parseFloat(info.w_highestPrice).toFixed(2), { useGrouping: false }) : ' '
                }
              }
            }
            return boxTable
          }
        })
        return listContractor
      },
      yearList: function () {
        const yearList = this.$store.state.Portal.agriObj.yearList
        return yearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
      },
      monthList: function () {
          return this.$store.state.Portal.agriObj.monthList
      },
      weekList: function () {
        return this.$store.state.Portal.agriObj.weekList
      },
      divisionList: function () {
        return this.$store.state.Portal.agriObj.divisionList
      },
      priceEntryTypeList: function () {
        return this.$store.state.Portal.agriObj.priceEntryTypeList
      },
      timePreiodList: function () {
        return this.$store.state.Portal.agriObj.timePreiodList
      },
      i18 () {
        return this.$i18n.locale
      },
      priceTypeList: function () {
        return this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === 'Market')
      },
      columns () {
        const labels = [
          { label: this.$t('damReport.commodity_group'), class: 'text-center', sortable: true },
          { label: this.$t('damReport.product_name_and_description'), class: 'text-center', sortable: true }
        ]
        const keys = []
        if (this.$i18n.locale === 'bn') {
          keys.push(
            { key: 'group_name_bn' },
            { key: 'commodity_name_bn' }
          )
        } else {
          keys.push(
            { key: 'group_name' },
            { key: 'commodity_name' }
          )
        }
        if (this.search.price_type_id.length > 0) {
          if (this.search.price_type_id.includes('Retail')) {
            labels.push(
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.retail_price_max_min'), class: 'text-center', sortable: true }
            )
            keys.push(
              { key: 'unit_name_bn' },
              { key: 'r_lowestPrice' }
            )
          }
          if (this.search.price_type_id.includes('Wholesale')) {
            labels.push(
              { label: this.$t('damReport.measurement'), class: 'text-center', sortable: true },
              { label: this.$t('damReport.wholesale_price_max_min'), class: 'text-center', sortable: true }
            )
            keys.push(
              { key: 'w_unit_name_bn' },
              { key: 'w_lowestPrice' }
            )
          }
        }
        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
      },
      loading () {
        return this.$store.state.commonObj.loading
      }
      // ,
      // priceTypeList () {
      //   return [
      //     { value: 1, text_en: 'Retail', text_bn: 'খুচরা', text: this.currentLocale === 'en' ? 'Retail' : 'খুচরা' },
      //     { value: 2, text_en: 'Wholesale', text_bn: 'পাইকারি', text: this.currentLocale === 'en' ? 'Wholesale' : 'পাইকারি' }
      //   ]
      // }
    },
    watch: {
        values_division: function (newVal) {
          if (newVal.length) {
            this.districtList = this.getDistrictListCustom(newVal)
          } else {
            this.districtList = []
          }
        },
        values_district: function (newVal) {
          if (newVal.length) {
            this.upazilaList = this.getUpazilaListCustom(newVal)
          } else {
            this.upazilaList = []
          }
        },
        values_upazila: function (newVal) {
          if (newVal.length) {
            this.marketList = this.getMarketListCustom(newVal)
          } else {
            this.marketList = []
          }
        }
    },
    methods: {
      pdfExport () {
          let reportTitle = this.$t('damReport.dailyPriceListReport')
          if (this.search.select_type !== 'Daily') {
            reportTitle = this.$t('damReport.weeklyPriceListReport')
          }
          ExportPdf.exportPdfDetails(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail', 13, reportTitle, this.datas, this, this.search)
      },
      getEditingData () {
          const tmpData = this.$store.state.list.find(item => item.id === this.id)
          return JSON.parse(JSON.stringify(tmpData))
      },
      headerDataExcel () {
        RestApi.getData(warehouseServiceBaseUrl, '/master-warehouse-report-heading/detail/13').then(response => {
          if (response.success) {
            const orgList = this.$store.state.commonObj.organizationProfileList.find(item => item.value === 13)
            const orgName = typeof orgList !== 'undefined' ? orgList.text_en : ''
            const orgNameBn = typeof orgList !== 'undefined' ? orgList.text_bn : ''
            const data = []
            data.address = response.data.address
            this.headerExcelDefault.orgName = orgName
            this.headerExcelDefault.orgNameBn = orgNameBn
            this.headerExcelDefault.address = response.data.address
            this.headerExcelDefault.address_bn = response.data.address_bn
          }
        })
      },
      async saveData () {
        Store.commit('mutateCommonProperties', {
          loading: true
        })
        this.showData = true
        const divisionObj = this.divisionList.find(item => item.value === this.search.division_id)
        this.search.division_name_en = typeof divisionObj !== 'undefined' ? divisionObj.text_en : ''
        this.search.division_name_bn = typeof divisionObj !== 'undefined' ? divisionObj.text_bn : ''
        const districtListObj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === this.search.district_id)
        this.search.district_name_en = typeof districtListObj !== 'undefined' ? districtListObj.text_en : ''
        this.search.district_name_bn = typeof districtListObj !== 'undefined' ? districtListObj.text_bn : ''
        const upazilaListObj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === this.search.upazila_id)
        this.search.upazila_name_en = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_en : ''
        this.search.upazila_name_bn = typeof upazilaListObj !== 'undefined' ? upazilaListObj.text_bn : ''
        const marketListObj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === this.search.market_id)
        this.search.market_name_en = typeof marketListObj !== 'undefined' ? marketListObj.text_en : ''
        this.search.market_name_bn = typeof marketListObj !== 'undefined' ? marketListObj.text_bn : ''
        const yearListObj = this.$store.state.Portal.agriObj.yearList.find(item => item.value === this.search.year)
        this.search.year_en = typeof yearListObj !== 'undefined' ? yearListObj.text_en : ''
        this.search.year_bn = typeof yearListObj !== 'undefined' ? yearListObj.text_bn : ''
        const monthListObj = this.$store.state.commonObj.monthList.find(item => item.value === this.search.month_id)
        this.search.month_en = typeof monthListObj !== 'undefined' ? monthListObj.text_en : ''
        this.search.month_bn = typeof monthListObj !== 'undefined' ? monthListObj.text_bn : ''
        const weekListObj = this.$store.state.Portal.agriObj.weekList.find(item => item.value === this.search.week_id)
        this.search.week_en = typeof weekListObj !== 'undefined' ? weekListObj.text_en : ''
        this.search.week_bn = typeof weekListObj !== 'undefined' ? weekListObj.text_bn : ''
        // let seachTypeEn = ''
        // let seachTypeBn = ''
        // this.search.price_type_id.forEach((element, key) => {
        //   if (key === 0) {
        //     seachTypeEn = element.text_en
        //     seachTypeBn = element.text_bn
        //   } else {
        //     seachTypeEn = seachTypeEn + ',' + element.text_en
        //     seachTypeBn = seachTypeBn + ',' + element.text_bn
        //   }
        // })
        // this.search.seachType_en = seachTypeEn
        // this.search.seachType_bn = seachTypeBn
        const marketIds = []
        this.values_market.forEach(item => {
          marketIds.push(parseInt(item.value))
        })
        this.search.market_id = marketIds
        const priceTypeIds = []
        this.values_price_type.forEach(item => {
          priceTypeIds.push(item.text_en)
        })
        this.search.price_type_id = priceTypeIds
        RestApi.postData(agriMarketingServiceBaseUrl, 'crop-price-info/reports/price-report/market-daily-price-report', this.search).then(response => {
          if (response.success) {
            Store.commit('mutateCommonProperties', {
              loading: false
            })
            if (response.data) {
              this.datas = this.getRelationalData(response.data)
            }
          } else {
            this.datas = []
            Store.commit('mutateCommonProperties', {
              loading: false
            })
          }
          this.firstTime = false
          this.clickSubmit = false
        })
      },
      getRelationalData (data) {
        const commodityGroupList = this.$store.state.Portal.agriObj.commodityGroupList
        const list = data.map(item => {
          const commodityGroupObj = commodityGroupList.find(com => com.value === item.com_grp_id)
          const commodityObj = this.$store.state.Portal.agriObj.commodityNameList.find(commodity => commodity.value === item.commodity_id)
          const rUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_retail)
          const wUnitObj = this.$store.state.Portal.agriObj.measurementUnitList.find(unit => unit.value === item.unit_wholesale)
          const commodityGroupData = {
            group_name: typeof commodityGroupObj !== 'undefined' ? commodityGroupObj.text_en : '',
            group_name_bn: typeof commodityGroupObj !== 'undefined' ? commodityGroupObj.text_bn : ''
          }
          const commodityData = {
            commodity_name: typeof commodityObj !== 'undefined' ? commodityObj.text_en : '',
            commodity_name_bn: typeof commodityObj !== 'undefined' ? commodityObj.text_bn : ''
          }
          const rUnitData = {
            unit_name: typeof rUnitObj !== 'undefined' ? rUnitObj.text_en : '',
            unit_name_bn: typeof rUnitObj !== 'undefined' ? rUnitObj.text_bn : ''
          }
          const wUnitData = {
            w_unit_name: typeof wUnitObj !== 'undefined' ? wUnitObj.text_en : '',
            w_unit_name_bn: typeof wUnitObj !== 'undefined' ? wUnitObj.text_bn : ''
          }
          return Object.assign({}, item, wUnitData, rUnitData, commodityGroupData, commodityData)
        })
        return list
      },
      getDivisionName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.divisionList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getDistrictList (divisionId = null) {
          return this.$store.state.Portal.agriObj.districtList.filter(item => item.division_id === divisionId)
      },
      getDistrictListCustom (divisionList) {
        const divisionIds = []
        divisionList.forEach(item => {
          divisionIds.push(parseInt(item.value))
        })
        this.search.division_id = divisionIds
        this.districtList = this.$store.state.Portal.agriObj.districtList.filter(item => divisionIds.includes(item.division_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })
        if (this.values_district.length) {
          const arr = []
          this.values_district.forEach(item => {
            const obj = this.districtList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_district = arr
        }
        return this.districtList
      },
      getDistrictName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.districtList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.districtList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getUpazilaList (districtId = null) {
          const upazilaList = this.$store.state.Portal.agriObj.upazilaList
          if (districtId) {
              return upazilaList.filter(upazila => upazila.district_id === districtId)
          }
          return upazilaList
      },
      getUpazilaListCustom (districtList) {
        const districtIds = []
        districtList.forEach(item => {
          districtIds.push(parseInt(item.value))
        })
        this.search.district_id = districtIds
        this.upazilaList = this.$store.state.Portal.agriObj.upazilaList.filter(item => districtIds.includes(item.district_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.values_upazila.length) {
          const arr = []
          this.values_upazila.forEach(item => {
            const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_upazila = arr
        }
        return this.upazilaList
      },
      getUpazilaName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getUpazilaNameCustom (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const market = this.$store.state.Portal.agriObj.marketList.find(item => item.upazila_id === parseInt(id))
            if (market !== undefined) {
              const obj = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === parseInt(id))
              arr.push(obj?.text)
            }
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.upazilaList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getMarketList (upazillaId = null) {
          const dataList = this.$store.state.Portal.agriObj.marketList.filter(item => item.upazila_id === upazillaId)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      },
      getMarketListCustom (upazilaList) {
        const upazilaIds = []
        upazilaList.forEach(item => {
          upazilaIds.push(parseInt(item.value))
        })
        this.search.upazila_id = upazilaIds
        this.marketList = this.$store.state.Portal.agriObj.marketList.filter(item => upazilaIds.includes(item.upazila_id)).map(el => {
          return Object.assign({}, el, { text: this.$i18n.locale === 'bn' ? el.text_bn : el.text_en })
        })

        if (this.values_market.length) {
          const arr = []
          this.values_market.forEach(item => {
            const obj = this.upazilaList.find(obj => obj.value === parseInt(item.value))
            if (obj !== undefined) {
              arr.push(item)
            }
          })
          this.values_market = arr
        }
        return this.marketList
      },
      getMarketName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.marketList.find(item => item.value === parseInt(id))
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.marketList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      },
      getNames (list) {
        const arr = []
        list.forEach(item => {
          arr.push(item?.text)
        })
        const str = arr.join(', ')
        return str.length > 50 ? str.substring(0, 40) + '..' : str
      },
      getPriceTypeList: function (entryType) {
          const dataList = this.$store.state.Portal.agriObj.priceTypeList.filter(item => item.entry_type === entryType)
          return dataList.map(item => {
              if (this.$i18n.locale === 'bn') {
                  return { value: item.value, text: item.text_bn }
              } else {
                  return { value: item.value, text: item.text }
              }
          })
      },
      getPriceTypeName (ids) {
        if (Array.isArray(ids)) {
          var arr = []
          ids.forEach(id => {
            const obj = this.$store.state.Portal.agriObj.priceTypeList.find(item => item.text_en === id)
            arr.push(obj?.text)
          })
          return arr.join(', ')
        } else {
          const data = this.$store.state.Portal.agriObj.priceTypeList.find(item => item.value === ids)
          return this.$i18n.locale === 'bn' ? data?.text_bn : data?.text_en
        }
      }
  }
}
</script>
